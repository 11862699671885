import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "willDeliverAt", "willDeliverAtRange" ]

  connect() {
    if (this.hasWillDeliverAtRangeTarget) {
      this.updateWillDeliverRange()
    }

    if (this.hasWillDeliverAtTarget) {
      $(this.willDeliverAtTarget).on("change.datetimepicker",
        event => this.updateWillDeliverAtDate(event));
    }
  }

  updateWillDeliverRange(event) {
    let datetime, text;
    const time = $('#guest_cart_will_deliver_at_time').val();

    if ( !time ) {    // offer_timeが空の時のjavascriptエラーを回避
      return;
    }

    if (document.getElementsByClassName('datetimepicker').length) {
      datetime = $('.datetimepicker').datetimepicker('date').hour(time.split(":")[0]).minutes(time.split(":")[1]);
      const timeFrame = $('#guest_cart_time_frame').data("time-frame");
      text = $('#will_deliver_at_range').data('end-time') ? datetime.format('MM月DD日(dd) HH:mm') + " 〜 " + datetime.add(timeFrame, 'minutes').format('HH:mm') : datetime.format('MM月DD日(dd) HH:mm') + "目安";
    } else {
      const date = document.getElementById('guest_cart_will_deliver_at').value;
      datetime = moment(`${date} ${time}`);
      text = datetime.format('MM月DD日(dd) HH:mm') + '目安';
    }

    $('#will_deliver_at_range').text(text);
    $('#will_deliver_at_note_time').text(datetime.format('HH:mm'));
  }

  updateWillDeliverAtDate(event) {
    // Prevent form submission on first page load.
    if (this.willDeliverAtTarget.getAttribute('data-datetimepicker-init-value') !== 'true') return;

    const origin_form = $("#cart_form");
    const reload_guest_cart_form = $("#reload_cart_form");

    reload_guest_cart_form.find('#payment_type').val(origin_form.find("[id^='guest_cart_payment_attributes_type_']:checked").val());
    reload_guest_cart_form.find('#guest_cart_will_deliver_at_time').val(origin_form.find('#guest_cart_will_deliver_at_time').val());
    reload_guest_cart_form.find('#will_deliver_at').val(origin_form.find("#guest_cart_will_deliver_at").val());

    reload_guest_cart_form.submit();
  }
}
