import { Controller } from "stimulus"

export default class extends Controller {
  static classes = [ "arrow" ]
  static targets = [ "slides" ]
  static values = { auto: Boolean, displayNumber: Number }

  connect() {
    this.execSlick(this.slidesTarget, `.arrows.${this.arrowClass}`, this.autoValue,
      this.displayNumberValue)
  }

  disconnect() {
    $(this.slidesTarget).slick('unslick');
  }

  resize(event) {
    clearTimeout(this.debounce)
    this.debounce = setTimeout(() => {
      $(this.slidesTarget).slick('resize')
    }, 100)
  }

  execSlick(elem, arrowClass, autoMode, displayNumber) {
    jQuery(elem).not('.slick-initialized').slick({
      // 通常オプション
      dots: true,
      infinite: true,
      arrows: true,
      autoplay: autoMode,
      autoplaySpeed: 3000,
      slidesToShow: displayNumber,
      slidesToScroll: 1,
      prevArrow: arrowClass + ' .left-arrow',
      nextArrow: arrowClass + ' .right-arrow',

      // レスポンシブ対応
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  }
}
