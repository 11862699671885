import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "input2" ];

  // 0-9の数字以外が入っていたとしても、submitで弾かれるので、高速化のため、ここではvalidationしない
  check(e) {
    const str = this.inputTarget.value.replace(/\s/g, '').substring(0, 16);    // 空白を削除した文字列に変換（16桁にカット）

    this.input2Target.value = str;

    let result = str.substring(0, 4) + ' ' + str.substring(4, 8) + ' ' + str.substring(8, 12) + ' ' + str.substring(12, 16);

    // 頭尾がスペースなら、削除しておく
    result = result.trim();
    this.inputTarget.value = result;
  }
}
