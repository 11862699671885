import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["discountKind" ]

  connect() {
    this.showDiscountValue(this.discountKindTarget.value);
  }

  toggleDiscountValue(event) {
    this.showDiscountValue(event.target.value)
  }

  showDiscountValue(val) {
    switch (val) {
      case "kind_percent" :
        $("#coupon_discount_value").show();
        $("#display_discount_kind").text(this.discountKindTarget.options[this.discountKindTarget.selectedIndex].text)
        break;
      case "kind_price" :
        $("#coupon_discount_value").show();
        $("#display_discount_kind").text(this.discountKindTarget.options[this.discountKindTarget.selectedIndex].text)
        break;
      case "kind_delivery_fee" :
        $("#coupon_discount_value").hide();
        $("#display_discount_kind").text("")
        break;
    }
  }
}
