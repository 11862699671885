import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list", "button"];

  connect() {
    if ( !this.hasButtonTarget ) {    // HTMLが描画されず、buttonTargetが取得できない場合がある
      return;
    }
    this.buttonTarget.addEventListener("ajax:success", (event) => {
      const response = event.detail[0];

      if (response == " ") {
        return;
      }

      this.listTarget.innerHTML += response.querySelector("body").innerHTML;

      const url = new URL(this.buttonTarget.href);

      if ( url.searchParams.has("page_max") == true ) {
        if ( Number(url.searchParams.get("page_max")) == Number(url.searchParams.get("page")) ) {
          this.buttonTarget.parentElement.classList.add("hidden");
        }
      }

      url.searchParams.set("page", Number(url.searchParams.get("page")) + 1);

      this.buttonTarget.href = url.toString();
    });
  }
}
