import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "useTarget" ]

  connect() {
    this.getStatusName()
    this.showTargetSelect(this.useTargetTarget.value);
  }

  toggleUseTarget(event) {
    this.showTargetSelect(event.target.value)
  }

  showTargetSelect(val) {
    switch (val) {
      case "target_all" :
        $("#client_select").hide();
        $("#facility_select").hide();
        break;
      case "target_client" :
        $("#client_select").show();
        $("#facility_select").hide();
        break;
      case "target_facility" :
        $("#client_select").hide();
        $("#facility_select").show();
        break;
    }
  }

  generateCode() {
    $.ajax({
      url: '/coupons/generate_coupon_code/',
      dataType: 'text',
      type: 'GET',
      cache: false,
    })
    .done(function(data, textStatus, jqXHR) {
      $('#coupon_code').val(data)
    })
    .fail(function( jqXHR, textStatus, errorThrown ) {
        cosole.log("クーポンコードの発行に失敗しました")
        alert("クーポンコードの発行に失敗しました")
    });
  }

  preventClose() {
    event.stopPropagation();
  }

  getStatusName() {
    if ($('.select-status').is(':checked')) {
      $('.dropdown-status').text($('.select-status:checked').next("span").text())
    }
  }
}
