import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "counter", "form"];

  connect() {
    // disconnectに書くべきだが、一部の表示が残ってしまうため初期化に毎回実行している
    this.closeModal();

    if ( !this.hasFormTarget ) {    // ターゲット自体がnullの場合javascriptエラーを回避
      return;
    }
    this.formTarget.addEventListener("ajax:success", (event) => {
      this.closeModal();
    });

    this.formTarget.addEventListener("ajax:error", (event) => {
      this.closeModal();
    });

  }

  increment() {
    let count = this.counterTarget.value
    count++
    this.counterTarget.value = count
  }

  decrement() {
    let count = this.counterTarget.value
    count--
    if (count < 1) count = 1;
    this.counterTarget.value = count
  }

  closeModal() {
    $(this.element).hide().removeAttr('aria-modal').attr('aria-hidden', 'true');
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }
}
