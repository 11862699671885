import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "statuschange",
    "updatestatus",
    "beforestatus",
    "statusform",
    "paymentform",
  ];
  static values = { slug: String };

  openStatusChangeConfirm(event) {
    document.getElementById(this.slugValue + "-after-status").textContent =
      this.statuschangeTargets[event.target.dataset.greetNum].name;

    $("#" + this.slugValue + "-status-change-modal").modal("hide");

    this.updatestatusTarget.value =
      this.statuschangeTargets[event.target.dataset.greetNum].value;

    if (this.updatestatusTarget.value != "status_ready") {
      $("#" + this.slugValue + "-status-change-confirm-modal").modal("show");
    } else {
      $("#" + this.slugValue + "-status-ready-modal").modal("show");
    }

    $("#" + this.slugValue + "-before-status").removeClass();
    $("#" + this.slugValue + "-after-status").removeClass();
    $("#" + this.slugValue + "-before-status").addClass(
      "confirm-label " + this.beforestatusTarget.value
    );
    $("#" + this.slugValue + "-after-status").addClass(
      "confirm-label " + this.updatestatusTarget.value
    );
  }
  openStatusChange() {
    if (this.updatestatusTarget.value != "status_ready") {
      $("#" + this.slugValue + "-status-change-confirm-modal").modal("hide");
    } else {
      $("#" + this.slugValue + "-status-ready-modal").modal("hide");
    }
    this.statusformTarget.submit();
  }
  backStatusBackSelect() {
    if (this.updatestatusTarget.value != "status_ready") {
      $("#" + this.slugValue + "-status-change-confirm-modal").modal("hide");
    } else {
      $("#" + this.slugValue + "-status-ready-modal").modal("hide");
    }
    $("#" + this.slugValue + "-status-change-modal").modal("show");
  }
  submitStatusPaid() {
    $("#" + this.slugValue + "-order-detail-modal").modal("hide");
    this.paymentformTarget.submit();
  }
}
